* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  background-color: #f3f5e4;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Calendar styles */
.calendar2-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.react-datepicker__day--selected {
  background-color: #fa6900 !important;
  color: white;
}

.react-datepicker__header {
  background-color: white !important;
}

.react-datepicker {
  font-family: "DM Sans", sans-serif !important;
  background-color: #f3f5e4 !important;
  border: none !important;
  box-shadow: 15px 6px 13px -7px rgba(95, 95, 95, 0.72);
  -webkit-box-shadow: 15px 6px 13px -7px rgba(95, 95, 95, 0.72);
  -moz-box-shadow: 15px 6px 13px -7px rgba(95, 95, 95, 0.72);
}

.calendar2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: min-content auto;
  overflow-x: auto;
  border: solid 1px #e0e4cc;
  position: relative;
}

.calendar2-header {
  display: flex;
}

.calendar2-body {
  display: flex;
  grid-column: 1/2;
  grid-row: 2/3;
  position: relative;
}

.place2-separator {
  border: 1px solid #ddd;
  width: 150px;
  height: 60px !important;
  display: flex;
  background-color: #e0e4cc;
  align-items: center;
  justify-content: center;
}

.time2-cell {
  position: sticky;
  padding: 20px 50px;
  top: 0;
  border: 1px solid #ddd;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e4cc;
  font-weight: bold;
  z-index: 1;
}

.time2-slot {
  width: 100px;
}

.calendar2-lectors {
  position: sticky;
  left: 0;
  display: flex;
  flex-direction: row;
  background-color: #e0e4cc;
  z-index: -1;
}
.extraRoom {
  position: sticky;
  top: 0;
  z-index: 1002;
}
.calendar2-lectors li {
  min-width: 400px;
  z-index: 1;
}

.calendar2-body li {
  border: 1px solid #ddd;
  width: 150px;
  height: 100px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  position: relative;
  /* Add relative positioning */
  z-index: -1;
  /* Ensure cells are behind ticket events */
}

.newLi {
  border: 2px solid #ddd;
  background-color: #f2f5e5;
  width: calc(100% - 20px);
  max-width: 400px;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px;
  z-index: 1;
}
.calendar2-body ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.event {
  position: absolute;
  height: 50px;
  font-size: 0.6em;
  color: black;
  line-height: 1.2em;
  z-index: 1;
  top: 20px;
  left: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.event:hover {
  transform: scale(1.001);
}

.event__title {

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.2em;
  top: 20px;
  left: 20px;
}
.extraTime {
  position: sticky;
  left: 0;
  z-index: 1000;
  background-color: #e0e4cc;
}
.z2.event:after {
  display: none !important;
}

.z2.event {
  content: "";
  border: solid 1px #c5c5c5;
  position: absolute;
  width: 400px;
}
.another {
  position: sticky;
  top: 0;
  z-index: 1;
}
.status-circle {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff3000;
  border: solid 1px #fff;
  top: 10px;
  left: 8px;
  position: absolute;
}

.event-no {
  width: 5px;
  text-align: center;
  top: 30px;
  left: 8px;
  position: absolute;
  white-space: nowrap;
}

.event-no span {
  display: inline-block;
  white-space: normal;
}

@media only screen and (max-width: 600px) {
  .calendar2-body li {
    height: 75px;
    width: 227px;
    max-width: 100%;
  }

  .time2-cell {
    max-height: 75px;
    min-width: 50px;
    font-size: 12px;
  }
  .calendar2-lectors li {
    min-width: 10px;
  }
  .event__title {
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 1em;
  }
  .calendar2-body li {
    width: calc(100vw - 50px);
  }
  .calendar2-body ul {
    scroll-snap-align: start;
    scroll-snap-type: x mandatory;
  }
  .newLi {
    width: 50px;
    padding: 0;
  }
  .time2-cell {
    padding: 0;
    width: 50px;
  }
  .time2-slot {
    padding: 0;
    width: 50px;
  }
}
