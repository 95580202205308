.calendar-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: calc(100vw - 270px);
  position: sticky;
}

.react-datepicker__day--selected {
  background-color: #fa6900 !important;
  color: white;
}
.react-datepicker__header {
  background-color: white !important;
}
.react-datepicker {
  font-family: "DM Sans", sans-serif !important;
  background-color: #f3f5e4 !important;
  border: none !important;
  box-shadow: 15px 6px 13px -7px rgba(95, 95, 95, 0.72);
  -webkit-box-shadow: 15px 6px 13px -7px rgba(95, 95, 95, 0.72);
  -moz-box-shadow: 15px 6px 13px -7px rgba(95, 95, 95, 0.72);
}

.calendar {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
  max-height: calc(100vh - 180px);
  max-width: calc(100% - 250px);
  overflow-x: scroll;
  border: solid 1px #e0e4cc;
  z-index: 1;
  position: absolute;
}

.calendar-header {
  display: flex;
}

.calendar-body {
  display: flex;
  grid-column: 1/2;
  grid-row: 2/3;
  position: relative;
}

.place-separator {
  border: 1px solid #ddd;
  width: 150px;
  height: 100px !important;
  display: block;
  display: flex;
  background-color: #fa69000d;
  align-items: center;
  justify-content: center;
}

.time-cell {
  position: sticky;
  padding: 20px 50px;
  top: 0;
  border: 1px solid #ddd;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e4cc;
  font-weight: bold;
  z-index: 10;
  max-width: 150px;
}

.calendar-lectors {
  position: sticky;
  left: 0;
  z-index: 1000;
  background-color: #e0e4cc;
}
.calendar-lectors li {
  align-items: center;
}
.calendar-body li {
  border: 1px solid #ddd;
  width: 150px;
  height: 100px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.calendar-body ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event {
  position: absolute;
  height: 50px;
  font-size: 0.7em;
  z-index: 1 !important;
  color: black;
  line-height: 1.2em;
}
.event__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.z2.event:after {
  display: none !important;
}

.z2.event {
  content: "";
  border: solid 1px #c5c5c5;
}
.status-circle {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff3000;
  border: solid 1px #fff;
  top: 10px;
  left: 8px;
  position: absolute;
}
.event-no {
  width: 5px;
  text-align: center;
  top: 30px;
  left: 8px;
  position: absolute;
  white-space: nowrap;
}
.event-no span {
  display: inline-block;
  white-space: normal;
}
@media only screen and (max-width: 600px) {
  .calendar {
    max-width: calc(100%);
    max-height: calc(100dvh - 170px);
  }

  .z2.event {
    height: 70px;
  }
  .calendar-body li {
    height: 70px;
  }
  a[role="button"].z2 {
    font-size: 0.7em;
    margin-right: 10px;
  }
  .place-separator {
    height: 70px !important;
  }
}

.add_member_button {
  margin-left: 10px;
}
.add_trainer_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-top: 10px;

  background-color: white;
  gap: 20px;
}
.delete-place-member {
  position: absolute;
  top: 5px;
  left: 5px;
  outline: none;
  border: none;
  color: #d67373;
  width: 15px;
  height: 15px;
  padding: 0;
  border-radius: 50%;
  width: 17px;
  height: 16px;
  font-size: 15px;
  background: none;
  content: "x";
}
.delete-place-member:hover {
  color: #e85454;
  text-shadow: 0 0 10px #000;
  transform: scale(1.3);
  transition: all 0.2s;
  cursor: pointer;
}
.calendar-header {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  width: calc(100vw - 270px);
  top: 0;
  z-index: 1000;
}

.skeleton {
  background-color: #eee;
  border-radius: 4px;
}
.calendar-header-skeleton {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 30px;
  margin-top: 20px;
  width: calc(100vw - 270px);
  top: 0;
  z-index: 1000;
}

.daily-overview-skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  margin-top: 20px;
  width: calc(100vw - 270px);
  top: 0;
  z-index: 1000;
  height: 60vh;
}

@keyframes loading {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #eee;
  }
}

.skeleton {
  animation: loading 1s linear infinite;
}

@media only screen and (max-width: 600px) {
  .calendar-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .calendar-header-skeleton {
    width: 90vw;
    margin: 5vw;
  }
  .daily-overview-skeleton {
    width: 90vw;
    margin: 5vw;
  }
}

.tile-settings-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: white;
  min-width: 300px;
}

.setting-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.setting-item label {
  font-weight: 500;
}

.setting-item input[type="number"] {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

/* These are the critical styles to ensure the modal appears on top */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 100%;
  max-width: 500px;
  z-index: 10000;
}
.tile-settings-modal {
  padding: 20px;
}

.toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.toggle-label {
  font-size: 16px;
  color: #333;
}

.toggle-button {
  width: 50px;
  height: 25px;
  border-radius: 15px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: #fa6900 !important;
}

.toggle-button.disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 2.5px;
  left: 3px;
  transition: transform 0.3s ease;
}

.toggle-button.active .toggle-circle {
  transform: translateX(25px);
}

.toggle-limit-info {
  margin-top: 15px;
  font-size: 14px;
  color: #333;
  text-align: center;
}
.text-red {
  color: red;
  font-weight: bold;
}

.collapsible-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.arrow-icon {
  font-size: 1.2rem;
}

.arrow-icon.open {
  transform: rotate(90deg);
}
