.payment-modal-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
}
.z2.app_modal .app_modal_container .app_modal_body {
  padding: 0 !important;
}

.payment-modal-item-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
