form > section {
  border: solid 2px #e0e4cc;
  border-radius: 0.5em;
  padding: 10px;
  margin-bottom: 1em;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
dl {
  overflow: hidden;
}
dt {
  width: 30%;
  margin-bottom: 0.5em;
  clear: both;
}
dd {
  width: 70%;
  margin-bottom: 0.5em;
}

dd,
dt {
  display: block;
  margin: 0;
  padding: 0;
  float: left;
}
section hr {
  margin: 1em -0.5em 1em -0.5em;
}

hr {
  clear: both;
  border: none;
  margin: 1em;
  border-bottom: solid 1px #e0e4cc;
}
table {
  border-collapse: collapse;
  width: 100%;
}
* {
  box-sizing: border-box;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: white;
  background-color: white;
  border: 0;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  border: 0;
}
td {
  border: 0;
}
table {
  border-collapse: collapse;
}

.styled_radio_group button {
  border-collapse: collapse;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.2);
  font: inherit;
  padding: 0.5em;
  transition: all 0.1s;
  overflow: visible;
  user-select: none;
  border-color: #a7dbd8;
  border-width: 2px;
  background-color: white;
  width: auto;
  display: inline-block;
  border-radius: 0;
  margin-left: 0;
  border-left: none;
  border-left-width: 0;
  position: relative;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
}
.styled_radio_group button.active {
  background-color: #a7dbd8;
}
.styled_radio_group button.loading {
  color: #7d7d7d;
}

.styled_radio_group button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: solid 2px #a7dbd8;
}
.styled_radio_group {
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
}

button,
input[type="submit"],
input[type="file"] {
  display: inline-block;
  width: auto;
}
button,
a[role="button"],
input[type="submit"],
input[type="file"] {
  border-color: #a7dbd8;
  border-width: 2px;
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.2);
  font: inherit;
  padding: 0.5em;
  border-radius: 0.2em;
  transition: all 0.1s;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.attendance_manager {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bullet_green,
.bullet_red,
.bullet_orange,
.bullet_silver {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.bullet_green {
  background-color: green;
}
.bullet_red {
  background-color: red;
}
.bullet_orange {
  background-color: orange;
}
