:root {
  --veronese_green: #00a671;
  --monticello: #f6ca23;
  --zooza_orange: #fa6900;
  --tyrian_purple: #66023c;
  --neutral: #bebebe;
}

.hidden {
  display: none;
}
.body {
  margin: 20px;
  min-height: 100vh;
}
@media only screen and (max-width: 600px) {
  .body {
    margin: 0;
  }
}

.language_selector {
  display: flex;
  align-items: bottom;
  align-content: bottom;
  bottom: 0;
  gap: 10px;
  color: #fa6900;
}
.login {
  display: grid;
  grid-template-columns: 1fr minmax(200px, 500px) 1fr;
  grid-template-rows: 4fr 1fr 1fr;
  height: 90vh;
}
* {
  box-sizing: border-box;
}

.spinner-button {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
h2 {
  margin: 0;
}
a {
  text-decoration: none;
  color: #fa6900;
}
h6 {
  margin: 0;
}
h5 {
  margin: 0;
}
h4 {
  margin: 0;
}
h3 {
  margin: 0;
}

h1 {
  margin: 0;
}
.react-datepicker__time--selected {
  background-color: #fa6900 !important;
  color: white;
  z-index: 9999 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #fa6900 !important;
  color: white;
}
.react-datepicker__time-container .react-datepicker__time {
  background-color: #dfe4cc !important;
}
.react-datepicker-popper {
  position: absolute !important;
  z-index: 9999 !important;
}
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="search"] {
  outline: none;
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.2);
  font: inherit;
  line-height: 21px;
  padding: 0.5em;
  border-radius: 0.2em;
  transition: all 0.1s;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input[type="number"]:focus {
  outline: none;
}
.input_number {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
}
.input_number label {
  font-size: 1em;
  font-weight: 600;
}

.input_number label {
  position: relative;
  display: block;
}
.input_number .stepper {
  max-width: 100%;
}

.stepper {
  font-size: 0.875em;
  max-width: 10em;
  position: relative;
}
.input_number button {
  color: #fa6900;
  outline: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
}
.input_number button#step_up {
  right: 0.22em;
}
.input_number button#step_down {
  left: 0.22em;
}
.input_number button {
  display: block;
  width: 1.3em;
  height: 1.3em;
  text-align: center;
  position: absolute;
  top: 0.22em;
  text-decoration: none;
  font-size: 1.5em;
  line-height: 1.1em;
  transition: all 0.2s;
}
.input_number button:disabled {
  color: #989898;
}
.input_number input {
  text-align: center;
}
input[type="text"] {
  padding: 0.5em;
  outline: none;
  border: solid 1px rgba(0, 0, 0, 0.2);
  line-height: 21px;
}
input[type="search"] {
  border: 0;

  border-radius: 50%;
}
input[type="search"]::-webkit-search-cancel-button {
  background-color: #ccc !important;
  color: #b01d1d !important;
  cursor: pointer !important;
}

/* Style the cancel button on hover */
input[type="search"]::-webkit-search-cancel-button:hover {
  background-color: #999 !important; /* Change background color on hover */
  color: #fff !important; /* Change text color on hover */
}
textarea {
  width: 100%;
  min-height: 6em;
}

blockquote {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.25em;
  padding: 0.5em;
  font-size: 0.75em;
  font-style: italic;
}

.list_item_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.z2.results_table.disabled {
  color: #e0e4cc;
  z-index: 0;
}

a.disabled {
  color: #989898;
}

a.z2.disabled {
  color: #e0e4cc;
  outline: none;
  border: none;
}
a.z2.disabled:focus:hover {
  color: #e0e4cc;
  outline: none;
  border: none;
}

.app_menu {
  z-index: 3;
}
@media screen and (max-width: 600px) {
  .app_menu {
    z-index: 4;
  }
  .z2.app_page_layout .app_page_toolbar {
    z-index: 3;
  }
}
.color_swatch {
  border-radius: 50%;
  display: block;
  width: 20px;
  height: 20px;
  border: solid 2px transparent;
}
.color_swatch.swatch_tyrian_purple {
  background-color: #66023c;
}
.color_swatch.swatch_zooza_orange {
  background-color: #fa6900;
}

.color_swatch.swatch_monticello {
  background-color: #f6ca23;
}
.color_swatch.swatch_veronese_green {
  background-color: #00a671;
}
.color_swatch.swatch_neutral {
  background-color: #5a5b52;
}

.swatch_list {
  display: flex;
  gap: 5px;
}

.date_time {
  display: flex;

}

.persons_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  grid-column: span 4;
}

.registration_person {
  border-radius: 20px;
  border: solid 1px #e0e4cc;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: rgba(255, 255, 255, 1);
  gap: 2px;
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color */
}

.registration_person h5 {
  margin: 0;
}

.registration_person.active {
  border: solid 2px black;
}
