[role="button"].rounded {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 2;
  padding: 0;
  display: inline-block;
  text-align: center;
}
fieldset {
  border: none;
  margin: 0;
  padding-left: 0;
}
.z2 section fieldset {
  margin-top: 0;
  margin-bottom: 0;
}
.app_modal_errors {
  color: red;
  font-size: 0.8em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 10px;
  padding-bottom: 0;
}
