.event_statistics {
  ul  {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  li  {

    flex: 1;

    @media screen and (max-width: 700px) {
      flex-basis: 33.333%;
    }

    span    {
      display: block;
    }

    span:first-child  {
      font-size: .750em;
      text-transform: uppercase;
      margin: 0;
      font-weight: normal;
      color: #a7ab99;
      letter-spacing: -.05em;
    }
  }
}

.registration_list.fluid    {
  width: 100%;
  padding-bottom: 1em;

  .small  {
    font-size: .750em;
  }

  .select    {
    display: inline-block;
    width: 100%;

    .styled_radio_group {
      margin-bottom: 10px;
    }
  }

  .name a {
    font-weight: bold;
  }

  h5, div.row_header  {
    font-size: .750rem;
    text-transform: uppercase;
    margin: 0;
    font-weight: normal;
    color: #a7ab99;
    letter-spacing: -.05em;
  }

  div.row_header  {
    display: flex;
    justify-content: space-between;
  }

  .registration_card.not_paid {
    border-color: red;
    .payment    {
      color: red;
    }
  }

  .registration_card   {
    position: relative;
    border-radius: .5em;
    border: solid 2px #E0E4CC;
    display: grid;
    grid-template-columns: 2em 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    @media screen and (max-width: 700px) {
      grid-template-columns: 2em 60% auto;

    }

    .expand {
      display: block;
      background-color: rgba(224, 228, 204, 0.5);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 10px;
      transition: all .2s;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      @media screen and (max-width: 700px) {
        height: 30px;
      }
    }

    .expand:before  {
      width: 100%;
      text-align: center;
      content: "\e918";
      font-family: 'icomoon';
      text-decoration: none;
      display: inline-block;
      line-height: 10px;
      height: 10px;
      position: absolute;
      top: 0;
      left: 0;

      @media screen and (max-width: 700px) {
        height: 30px;
        line-height: 30px;
      }
    }

    .expand:hover   {
      background-color: rgba(224, 228, 204, 1);
      height: 15px;
      transition: all .2s;

      @media screen and (max-width: 700px) {
        height: 30px;
      }
    }

    .name,
    .payment,
    .attendance,
    .custom_field,
    .index  {
      padding-bottom: 0px;
    }

    .slots,
    .note  {
      padding-bottom: 15px;
    }

    .slots {
      @media screen and (max-width: 700px) {
        padding-bottom: 35px;
      }
    }

    .name   {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row: 1;
      border-bottom-color: #E0E4CC;

      @media screen and (max-width: 700px) {
        grid-column-start: 2;
        grid-column-end: 2;
      }
    }

    .payment    {
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row: 1;
      border-bottom-color: #E0E4CC;

      @media screen and (max-width: 700px) {
        grid-column-start: 3;
        grid-column-end: 3;
        border-right-color: transparent;
      }
    }

    .attendance {
      grid-column-start: 5;
      grid-column-end: 8;
      grid-row: 1;
      border-bottom-color: #E0E4CC;

      @media screen and (max-width: 700px) {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row: 2;
        border-right-color: transparent;
      }
    }

    .custom_field {
      grid-column-start: 8;
      grid-column-end: 12;
      grid-row: 1;
      border-bottom-color: #E0E4CC;
      border-right: none;

      @media screen and (max-width: 700px) {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row: 3;
      }
    }

    .note {
      grid-column-start: 3;
      grid-column-end: 12;
      grid-row-start: 3;
      grid-row-end: 3;
      border-right: none;
      border-bottom: none;

      @media screen and (max-width: 700px) {
        grid-column-start: 1;
        grid-column-end: span 3;
        grid-row: auto;
        border-bottom: dotted 1px #E0E4CC;
      }
    }

    .stats   {

      grid-column: 3 / 12;
      grid-row: 4 / 5;

      @media screen and (max-width: 700px) {
        grid-column-start: 1;
        grid-column-end: span 3;
        grid-row: auto;
        border-bottom: dotted 1px #E0E4CC;
      }

    }

    .slots {
      grid-column-start: 2;
      grid-column-end: span 1;
      grid-row-start: 3;
      grid-row-end: 3;
      border-bottom: none;

      @media screen and (max-width: 700px) {
        grid-column-start: 1;
        grid-column-end: span 3;
        grid-row: auto;
        border-right-color: transparent;
      }
    }

    .ef_dob,
    .ef_full_name   {
      grid-row-start: 2;
      grid-row-end: 2;
      grid-column-end: span 2;

      @media screen and (max-width: 700px) {
        grid-column-start: 1;
        grid-column-end: span 3;
        grid-row: auto;
        border-right-color: transparent;
      }
    }

    .ef_address {
      grid-column-start: 6;
      grid-column-end: 12;
      grid-row-start: 2;
      grid-row-end: 2;
      border-right: none;

      @media screen and (max-width: 700px) {
        grid-column-start: 1;
        grid-column-end: span 3;
        grid-row: auto;
        border-right-color: transparent;
      }
    }

    .index    {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 5;
      border: none;
      text-align: right;
      background-color: #E0E4CC;
      padding: .25em;
      width: 2em;
      text-align: center;
      color: #b36325;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;

      @media screen and (max-width: 700px) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-end: 1;
        border-bottom-left-radius: 0;
      }
    }

    > div   {
      padding: .5em;
      border-right: dotted 1px #E0E4CC;
      border-bottom: dotted 1px #E0E4CC;
    }
  }

  .registration_card.closed   {

    .name,
    .payment,
    .attendance,
    .custom_field,
    .index  {
      padding-bottom: 15px;
      border-bottom-color: transparent;
      @media screen and (max-width: 700px) {
        border-bottom-color: #E0E4CC;
      }
    }

    .slots,
    .note  {
      padding-bottom: .5em;
    }

    .custom_field   {
      @media screen and (max-width: 700px) {
        padding-bottom: 35px;
      }
    }

    .ef_address,
    .ef_dob,
    .ef_full_name,
    .slots,
    .note,
    .stats    {
      display: none;
    }

    .expand:before  {
      content: "\e914";
    }
  }

  .registration_card + .registration_card {
    margin-top: .5em;
  }

}
.full_width .registration_list  {
  width: 100%;
}
.registration_list  {
  width: calc( 70% - .5em );
  margin-right: .5em;
  float: left;

  @media screen and (max-width: 700px) {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .warning_bullet {

    font-size: 10px;
    border-radius: 50%;
    background-color: red;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    color: white;

  }

  .registration_card_start,
  .registration_card_end,
  .registration_card_row {
    border-radius: .5em;
    border: solid 2px #E0E4CC;
    display: grid;

    &.inactive  {
      border-color: #fcfcfc;
    }
  }

  .small  {
    font-size: .750em;
  }

  .select    {
    display: inline-block;
  }

  .name a {
    font-weight: bold;
  }

  .registration_card_start    {
    grid-template-columns: 12% 28% 20% 20% 20%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: dotted 1px #E0E4CC;

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }

  .clip_text  {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .break_text  {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .registration_card_end  {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
    grid-template-columns: 12% 43% 45%;

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }

  .registration_card_row.registration_card_row_payments   {
    grid-template-columns: 10% 18% 18% 18% 18% 18%;
  }

  .registration_card_row  {
    border-radius: 0;
    border-top: none;
    border-bottom: none;
    border-bottom: dotted 1px #E0E4CC;
    grid-template-columns: 10% 45% 45%;

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  }

  .registration_card_end + .registration_card_start   {
    margin-top: 1em;
  }

  .registration_card_end:last-of-type {
    margin-bottom: 1em;
  }

  .registration_card_start > div,
  .registration_card_row > div,
  .registration_card_end > div    {
    padding: .5em;
    border-right: dotted 1px #E0E4CC;

    @media screen and (max-width: 700px) {
      border-bottom: dotted 1px #E0E4CC;
      border-right: none;
    }

    h5  {
      font-size: .750em;
      text-transform: uppercase;
      margin: 0;
      font-weight: normal;
      color: #a7ab99;
      letter-spacing: -.05em;
    }
  }
//.status > span {
//  font-size: 0.6em
//}
  .registration_card_start > div:last-of-type,
  .registration_card_row > div:last-of-type,
  .registration_card_end > div:last-of-type {
    border-right: none;
    border-bottom: none;
  }
}
section {
  &.green {
    border-color: green;
  }
  &.red   {
    border-color: red;
  }
  border: solid 2px #e0e4cc;
  border-radius: .5em;
  padding: 10px;
  margin-bottom: 1em;
  box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.05);
  overflow: hidden;
}
aside {
  width: 30%;
  float: left;
}
aside section {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
aside section {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
@media screen and (max-width: 700px) {
  aside {
    width: 100%;
    float: none;
  }
}
select {
  padding: 0.5em;
  outline: none;
  border: solid 1px rgba(0, 0, 0, 0.2);
  line-height: 21px;
}
.block_help{
  margin-left: 10px;
}
.table th, table td {
  vertical-align: top;
  text-align: left;
  padding: 0.5em;
}
